import { createWebHistory, type RouteRecordRaw } from "vue-router";

import { createRouter } from "@tager/admin-layout";
import {
  ADMIN_FORM_ROUTE,
  ADMIN_LIST_ROUTE,
  ROLE_FORM_ROUTE,
  ROLE_LIST_ROUTE,
} from "@tager/admin-administrators";
import { SEO_TEMPLATES_ROUTE } from "@tager/admin-seo";
import {
  PAGE_COMMANDS_ROUTE,
  PAGE_CRON_LOGS_ROUTE,
  PAGE_COMMANDS_LOGS_ROUTE,
  PAGE_COMMANDS_LOG_DETAILS_ROUTE,
  PAGE_CRON_LOG_DETAILS_ROUTE,
  PAGE_COMMANDS_DETAILS_ROUTE,
} from "@tager/admin-cron";

import BrandList from "@/modules/brands/BrandList";
import BrandForm from "@/modules/brands/BrandForm";
import GenerationList from "@/modules/generations/GenerationList";
import GenerationForm from "@/modules/generations/GenerationForm";
import ComplectationForm from "@/modules/complectations/ComplectationForm";
import ModelList from "@/modules/models/ModelList";
import { UTILS_EXTERIOR_POINTS } from "@/modules/utils/exterior-points";
import Home from "@/views/Home.vue";
import { getLinks } from "@/constants/links";
import { ROUTE_PATHS } from "@/constants/paths";
import { ENGINE_FORM_ROUTE } from "@/modules/engines";
import { PARAMS_FORM_ROUTE, PARAMS_LIST_ROUTE } from "@/modules/params";
import {
  PARAMS_CATEGORIES_FORM_ROUTE,
  PARAMS_CATEGORIES_LIST_ROUTE,
} from "@/modules/params-categories";

export const HOME_ROUTE: RouteRecordRaw = {
  path: "/",
  component: Home,
  name: "Home",
  meta: {
    getBreadcrumbs: (route, { t }) => [{ url: "/", text: t("admin:home") }],
  },
};

export const BRAND_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.BRAND_LIST,
  component: BrandList,
  name: "Бренды",
  meta: {
    getBreadcrumbs: (route, { t }) => [
      getLinks(t).HOME,
      getLinks(t).BRAND_LIST,
    ],
  },
};

export const BRAND_FORM_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.BRAND_FORM,
  component: BrandForm,
  name: "Форма Бренда",
  meta: {
    getBreadcrumbs: (route, { t }) => [
      getLinks(t).HOME,
      getLinks(t).BRAND_LIST,
      {
        url: route.path,
        text: "Форма бренда",
      },
    ],
  },
};

export const MODEL_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.MODEL_LIST,
  component: ModelList,
  name: "Модели",
  meta: {
    getBreadcrumbs: (route, { t }) => [
      getLinks(t).HOME,
      getLinks(t).MODEL_LIST,
    ],
  },
};

export const GENERATION_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.GENERATION_LIST,
  component: GenerationList,
  name: "Поколения",
  meta: {
    getBreadcrumbs: (route, { t }) => [
      getLinks(t).HOME,
      getLinks(t).GENERATION_LIST,
    ],
  },
};

export const GENERATION_FORM_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.GENERATION_FORM,
  component: GenerationForm,
  name: "Форма поколения",
  meta: {
    getBreadcrumbs: (route, { t }) => [
      getLinks(t).HOME,
      getLinks(t).GENERATION_LIST,
      {
        url: route.path,
        text: "Форма поколения",
      },
    ],
  },
};

export const COMPLECTATION_FORM_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.COMPLECTATION_FORM,
  component: ComplectationForm,
  name: "Форма комплектации",
  meta: {
    getBreadcrumbs: (route, { t }) => [
      getLinks(t).HOME,
      {
        url: route.path,
        text: "Форма комплектации",
      },
    ],
  },
};

export const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH || "/"),
  routes: [
    HOME_ROUTE,
    BRAND_LIST_ROUTE,
    BRAND_FORM_ROUTE,
    MODEL_LIST_ROUTE,
    GENERATION_LIST_ROUTE,
    GENERATION_FORM_ROUTE,
    COMPLECTATION_FORM_ROUTE,
    ADMIN_FORM_ROUTE,
    ADMIN_LIST_ROUTE,
    ROLE_FORM_ROUTE,
    ROLE_LIST_ROUTE,

    ENGINE_FORM_ROUTE,
    PARAMS_LIST_ROUTE,
    PARAMS_FORM_ROUTE,
    PARAMS_CATEGORIES_LIST_ROUTE,
    PARAMS_CATEGORIES_FORM_ROUTE,

    PAGE_COMMANDS_ROUTE,
    PAGE_CRON_LOGS_ROUTE,
    PAGE_COMMANDS_LOGS_ROUTE,
    PAGE_COMMANDS_LOG_DETAILS_ROUTE,
    PAGE_CRON_LOG_DETAILS_ROUTE,
    PAGE_COMMANDS_DETAILS_ROUTE,
    UTILS_EXTERIOR_POINTS,
    SEO_TEMPLATES_ROUTE,
  ],
});
