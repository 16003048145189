<template>
  <PageLayout :sidebar-menu-list="sidebarMenuList">
    <router-view />
  </PageLayout>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

import { MenuItemType, PageLayout } from "@tager/admin-layout";
import { useI18n } from "@tager/admin-services";
import { HomeIcon, SettingsIcon, ViewListIcon } from "@tager/admin-ui";

import { getLinks } from "@/constants/links";

export default defineComponent({
  name: "App",
  components: { PageLayout },
  setup() {
    const { t } = useI18n();

    const links = computed(() => getLinks(t));

    const sidebarMenuList: Array<MenuItemType> = [
      { id: "home", icon: HomeIcon, ...links.value.HOME },
      { id: "brands", icon: ViewListIcon, ...links.value.BRAND_LIST },
      { id: "models", icon: ViewListIcon, ...links.value.MODEL_LIST },
      { id: "generations", icon: ViewListIcon, ...links.value.GENERATION_LIST },
      {
        id: "params",
        icon: ViewListIcon,
        ...links.value.PARAMS_CATEGORIES_LIST,
      },
      {
        id: "utils",
        icon: SettingsIcon,
        text: "Утилиты",
        children: [links.value.UTILS_IMAGE_POINTS],
      },
      {
        id: "seo",
        icon: ViewListIcon,
        ...links.value.SEO_TEMPLATES,
      },
      {
        id: "admins",
        text: "Администраторы",
        icon: SettingsIcon,
        children: [links.value.ADMIN_ADMINS, links.value.ADMIN_ROLES],
      },
    ];

    return {
      sidebarMenuList,
    };
  },
});
</script>

<style scoped lang="scss"></style>
